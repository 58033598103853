export const API_URL = "https://api.secondstage.app"
//export const API_URL = "http://localhost:8080"
//export const TOOLPRESS_API = "https://api-tool-press-yn26wrnl4q-ey.a.run.app"
export const TOOLPRESS_API = "https://toolpress-api.2ndstage.app"

export const THEME_CONFIG = {
    mode:"dark",
    gradients: {
        default: ['#fbb03b', '#fbb03b'],
        home_question: ['#fbb03b', '#fbb03b'],
        applausometro: ['#fbb03b', '#fbb03b'],
        siNo:['#fbb03b', '#fbb03b'],
        sentiment: ['#fbb03b', '#fbb03b'],
        wordcloud: ['#fbb03b', '#fbb03b'],
        qna: ['#fbb03b', '#fbb03b'],
    },
    primary: '#fbb03b',
    secondary: '#263238',
    accent: '#fbb03b',
    text: '#fbb03b',
    background:"#43447e",
    poll:{
        default:"rgba(255,255,255,0.65)",
        answered:"#fbb03b",
        correct: '#3A722D',
        wrong: '#DB0A16'
    }
}
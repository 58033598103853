import './App.css';
import {Navigate, Route, Routes} from "react-router-dom";
import pleshDark from "./theme/pleshDark";
import Vote from "./pages/Vote";
import React from "react";
import {Box, CssBaseline, ThemeProvider} from "@mui/material";
import footer from "./assets/images/banner-footer.png"
import {LogoBox} from "./components/VoteContent";
import logo from "./assets/images/header-next-gen-regione-toscana.png"

function App() {
    return (
        <ThemeProvider theme={pleshDark}>
            <CssBaseline/>
            <div className="App">
                <LogoBox logo={logo}/>
                <Box height={'10%'} position={'fixed'} bottom={-1} left={0} right={0}>
                    <img src={footer} width={'100%'} style={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: "0px",
                        background: "white",
                        paddingRight: "10px",
                    }}/>
                </Box>
                <Routes>
                    <Route path='/' element={<Navigate to={'/vote/957DacmayHCdh3np1Shl'}/>}/>
                    <Route path='/vote/:id' element={<Vote/>}/>
                </Routes>
            </div>
        </ThemeProvider>
    );
}

export default App;
